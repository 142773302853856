import { Link } from "@global";
import { SanityBlog } from "@graphql-types";
import { useStore } from "@state/store";
import { TABLET_BREAKPOINT, colors } from "@util/constants";
import { blockToText } from "@util/helper";
import { Container, H4, P, Separator } from "@util/standard";
import { navigate } from "gatsby-link";
import * as React from "react";
import styled from "styled-components";
import PreviewAllergens from "../../shared/sub/previewAllergens";

interface Props {
  blog: SanityBlog;
  noMargin?: boolean;
  category?: string;
  isGift?: boolean;
  isCarousel?: boolean;
  hasMarginBottom?: boolean;
  isCategoryResult?: boolean;
}

const StyledImageContainer = styled(Container)<{
  imageUrl?: string;
  isGift?: boolean;
}>`
  ${({ imageUrl }) =>
    imageUrl &&
    `background-image: url(${imageUrl}); 
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; 
    background-color: #eee; 

  `}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    // background-size: ${props => !props.isGift && "88px"};
  }
`;

const SquareLink = styled.a<{
  isGift?: boolean;
}>`
  margin-bottom: 26px;
  position: relative;
  ${({ isGift }) =>
    isGift &&
    `
    padding-bottom: 85%;
    width: 100%;
    > div{
      position: absolute;
    }
  `}
  > div {
    &:before {
      transition: opacity 0.3s ease;
      content: "";
      background: ${colors.white};
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      pointer-events: none;
      z-index: 0;
    }
    > div {
      position: relative;
      z-index: 1;
    }
  }
  &:hover {
    > div {
      &:before {
        opacity: 0.3;
      }
    }
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-bottom: 16px;
  }
`;

const StyledExcerpt = styled(P)`
  height: 42px;
  overflow: hidden;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 35px;
  }
`;

const FavoriteContainer = styled(Container)`
  position: absolute;
  right: 0;
  top: 0;
`;

const BlogPreview = (props: Props) => {
  const {
    blog,
    noMargin,
    category,
    isGift,
    isCarousel,
    hasMarginBottom,
    isCategoryResult,
  } = props;

  if (blog == null) {
    return null;
  }

  let trimmedExcept = "";

  if (blog.exceprt) {
    const blogExcerpt = blog.exceprt;
    trimmedExcept = blogExcerpt.substring(0, 80) + "...";
  }

  const relocate = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    navigate(`/blog/${main.slug?.current}`);
  };

  return (
    <Container
      margin={
        noMargin !== true
          ? hasMarginBottom
            ? "0 25px 35px 0"
            : "0px 25px 0px 0px"
          : "0"
      }
      tabletMargin={
        noMargin !== true
          ? hasMarginBottom
            ? "0 12px 20px 0"
            : "0px 12px 0px 0px"
          : "0"
      }
      display="flex"
      flexDirection="column"
      tabletWidth={isGift ? (isCarousel ? "300px" : "50%") : "250px"}
      mobileWidth={isGift ? (isCarousel ? "200px" : "50%") : "250px"}
      width="400px"
      overflow="hidden"
      position="relative"
      mobileMargin={isCategoryResult ? "10px 10px" : "10px auto"}
      className="blog-item"
    >
      <SquareLink
        onClick={() => navigate(`/blog/${blog.slug?.current}`)}
        isGift={isGift}
      >
        <StyledImageContainer
          isGift={isGift}
          width="400px"
          height="195px"
          tabletWidth={isGift ? "100%" : "250px"}
          tabletHeight={isGift ? "100%" : "218px"}
          imageUrl={blog.image?.asset?.url as string}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          className="blog-item-image"
        ></StyledImageContainer>
      </SquareLink>
      <FavoriteContainer>
        <PreviewAllergens isBlog id={blog.id} />
      </FavoriteContainer>

      <Container display="flex" flexDirection="column">
        <a onClick={() => navigate(`/blog/${blog.slug?.current}`)}>
          <H4 noMargin tabletFontSize={13} fontFamily="Poppins" fontSize={17}>
            {blog.title}
          </H4>
        </a>
        {blog.exceprt && (
          <P whiteSpace="normal" fontSize={14}>
            {trimmedExcept}
          </P>
        )}
        <Link linktext="Read more >" url={`/blog/${blog.slug?.current}`} />
      </Container>
    </Container>
  );
};

export default BlogPreview;
